
.goals-container {
    padding: 4rem 0rem;
    text-align: center;
    background-color: #7094BE;
  }
  
  .title {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 32px;
    margin-bottom: 1.5rem;
    color: white;
    margin-top: 2rem;
  }

  .title::after {
    content: '';
    position: absolute;
    left: 50%; 
    transform: translateX(-50%); 
    bottom: 0.9rem; 
    width: 412px; 
    height: 1px;
    background-color: white;
    opacity: 39%; 
    border-radius: 5px;
  }
  

  .goals-grid {
    padding: 5rem 3rem;
    align-items: center;
    justify-content: center;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    grid-template-rows: repeat(2, 1fr); 
    gap: 2rem; 
  }
  
  .goal-item {
    background-color: transparent;
    border: 1px solid white;
    padding: 2rem 1rem 1rem; 
    color: white;
    font-size: 1rem;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 162px;
    width: 285px;
    margin: 2rem auto; 
    position: relative; 
    opacity: 0;
  }
  
  .goal-icon {
    position: absolute;
    top: -1.2rem;
    background-color: #7094BE;
    height: 4rem; 
    width: 4rem;
    z-index: 2; 
  }
  
  
  .goal-text {
    color: white;
    font-size: 14px;
    text-align: center;
    margin-top: 1rem; 
  }
  

  @media (max-width: 768px) {
    .goals-grid {
      grid-template-columns: repeat(2, 1fr); 
      grid-template-rows: auto;
      gap: 1rem; 
    }
  
    .goal-item {
      height: 140px; 
      width: 100%; 
    }
  
    .goal-icon {
      height: 3rem;
      width: 3rem;
    }
  
    .goal-text {
      font-size: 12px; 
    }
  }
  
  
  @media (max-width: 480px) {

    .title::after {
      content: '';
      position: absolute;
      left: 50%; 
      transform: translateX(-50%); 
      top: 3rem; 
      width: 212px; 
      height: 1px;
      background-color: white;
      opacity: 0.39; 
      border-radius: 5px;
    }

    .title {
      font-size: 24px;
      position: relative;
    }
    .goals-grid {
      grid-template-columns: 1fr; 
    }
  
    .goal-item {
      height: 180px; 
      width: 100%; 
    }
  
    .goal-text {
      font-size: 12px; 
    }
  }

@media (min-width: 769px) and (max-width: 1024px) {

  .title::after {
    content: '';
    position: absolute;
    display: none;
    top: 3rem; 
    width: 212px; 
    height: 1px;
    background-color: white;
    opacity: 0.39; 
    border-radius: 5px;
  }

  .goals-grid {
    grid-template-columns: repeat(2, 1fr); 
    grid-template-rows: auto;
    gap: 2rem; 
  }

  .goal-item {
    height: 180px; 
    width: 80%; 
  }

  .goal-icon {
    height: 3rem;
    width: 3rem;
  }

  .goal-text {
    font-size: 14px; 
  }
}