.navbar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2.2rem 5rem;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.navbar-logo {
  display: flex;
  align-items: center; /* Aligns the logo and hamburger button horizontally */
}

.navbar-logo img {
  height: 40px;
  padding-top: 7px;
  margin-left: 0.5rem; /* Small spacing between the logo and hamburger button */
}

.navbar-logo img:hover {
  cursor: pointer;
}

.navbar-links {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.navbar-links a {
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  color: white;
  text-decoration: none;
  margin-right: 4rem;
  font-size: 16px;
  position: relative;
  padding-bottom: 5px;
}

.navbar-links a::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 3px;
  border-radius: 5px;
  bottom: 0;
  left: 50%;
  background-color: white;
  transition: width 0.3s ease-in-out, left 0.3s ease-in-out;
}

.navbar-links a:hover::after {
  width: 100%;
  left: 0%;
}

.navbar-links a:hover {
  color: #ddd;
  cursor: pointer !important;
}

.navbar-button {
  background-color: #ffffff;
  color: #7094BE;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
}

.navbar-button:hover {
  background-color: #0056b3;
}

.language-dropdown {
  position: relative;
  display: inline-block;
}

.language-btn {
  background-color: #ffffff;
  color: #498BD9;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.language-btn::after {
  content: '▼';
  font-size: 10px;
  margin-left: 0.5rem;
  color: #484848;
}

.globe-icon {
  width: 24px;
  height: auto;
  margin-right: 0.5rem;
}

.language-options {
  position: absolute;
  overflow: hidden;
  top: 100%;
  left: 0;
  color: #498BD9;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 0.5rem 0;
  list-style: none;
  z-index: 10;
  width: 100%;
}

.language-options li {
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-family: 'Manrope', sans-serif;
  font-weight: 500;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.language-options li:hover {
  background-color: #eee;
}

.language-dropdown.open .language-options {
  display: block;
}

.menu-toggle-btn {
  display: none; /* Hidden by default on desktop */
  align-items: center;
  justify-content: center;
  font-size: 24px;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  position: relative;
  margin-right: 1rem;
}



.menu-toggle-btn:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  opacity: 0;
  transition: transform 0.3s, opacity 0.3s;
  transform: scale(0);
}

.menu-toggle-btn:active:before {
  opacity: 1;
  transform: scale(4);
}

@media (max-width: 768px) {

  .language-btn {
    padding: 0.3rem 0.8rem;
  }
  .navbar {
    padding: 1rem 2rem;
  }

  .menu-toggle-btn {
    display: flex; 
    margin-left: 0; 
  }

  .navbar-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
  }

  .navbar-links.active {
    display: flex;
    transform: translateY(0);
  }

  .navbar-links a {
    margin-right: 0;
    margin-bottom: 2rem;
    font-size: 24px;
  }
  .language-dropdown {
    position: absolute;
    right: 1rem;
    top: 0.5rem;
  }
}
