  
  .reviews{
    margin-top: -6rem;
    padding: 2rem;
    text-align: center;
    background-color: #7094BE;
    padding-bottom: 10rem ;
  }
  .rev-title {
    font-family: 'Manrope', sans-serif;
    font-weight: 600;
    font-size: 32px;
    color: white;
    margin-top: 2rem;
    margin-bottom: -1rem;
  }

  .rev-title::after {
    content: '';
    display: block;   
    background-color: white;
    margin: 0 auto;
    margin-top: 1.5rem;
    width: 412px; 
    height: 1px !important;
    opacity: 39%; 
    border-radius: 5px;
  }

  .reviews-outer {
    background-color: #7094BE;
    text-align: center;
    color: white;
    margin: 0 auto;
    display: flex;
    align-items: center; 
    justify-content: center; 
  }
  
  .reviews-container {
    width: 50%; 
    padding: 2rem; 
    background-color: transparent;
    text-align: center;
  }
  
  
  .reviews-text {
    margin-bottom: 1.5rem;
    height: 70px;
  }
  
  .quote {
    font-size: 18px;
    font-weight: 100;
    margin-bottom: 0.5rem;
    
  }
  
  .author {
    font-size: 12px;
    color: #ccc;
    margin-top: 0.5rem;
  }
  
  .dots {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
  }
  
  .dot {
    height: 9px;
    width: 9px;
    margin: 0 5px;
    background-color: #ffffff;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
  }
  
  .dot.active {
    width: 6px;
    height: 6px;
    border: 2px solid white;
    background-color: #7094BE;
  }
  