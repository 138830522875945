@font-face {
  font-family: 'Manrope';
  src: url('../public/Manrope-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: normal;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

.App {
  text-align: center;
  background-color: #7094BE;
  width: 100%;
} 

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Manrope', sans-serif !important;
  scroll-behavior: smooth;
}


.landing-page {
  background-image: url('../src/Assets/bg.png') !important;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 75vh;
  width: 100%;
  background-color: #7094BE;
  overflow: hidden;
}

.landing-page-content {
  position: relative;
  z-index: 2;
  color: white;
  text-align: left;
  padding-top: 15rem;
  margin-left: 5rem;
  font-size: 32px;
}

.head {
  font-family: 'Manrope', sans-serif;
  font-weight: 900;
  position: relative;
  letter-spacing: 0.5px;
}

.landing-page-content h1 {
  margin: 0;
  padding: 0;
  line-height: 0.68;
  font-size: 64px;
  font-family: 'Manrope', sans-serif !important;
  font-weight: 900;
  letter-spacing: 0.5px;
}

.landing-page-content h1 + h1 {
  margin-top: 10px;
  font-family: 'Manrope', sans-serif !important;
  font-weight: 900;
  letter-spacing: 0.5px;
}

.landing-page-content h1:first-of-type {
  z-index: 2;
}

.box {
  display: inline-block;
  background-color: transparent;
  border-radius: 17px;
  padding: 1.5rem 1.2rem;
  font-weight: 900;
  letter-spacing: 0.5px;
  position: relative;
  left: 0;
}

.develop-text {
  margin: 0;
  padding: 0;
  text-align: left;
  font-size: 64px;
  font-family: 'Manrope', sans-serif !important;
  font-weight: 900;
}

.mng {
  z-index: 5 !important;
}

.clip-container.first-line {
  overflow: hidden;
  height: 100px;
  margin-bottom: -1.2rem;
  width: 100%; 
  display: flex;
  align-items: baseline;
  z-index: 2 !important;
}

.clip-container.first-line h1:first-of-type {
  margin-right: 10px;
}

.clip-container.second-line {
  overflow: hidden;
  height: 100px;
  width: 100%; 
  display: flex;
  align-items: baseline;
  transform: translateX(-1.2rem) !important;
}

@media (min-width: 1200px) {
  .landing-page-content {
    padding-top: 11rem;
    margin-left: 5rem;
  }
  
}

@media (max-width: 768px) {
  html, body {
    overflow-x: hidden !important;
  }
  .landing-page-content {
    padding-top: 10rem !important;
    margin-left: 2rem !important;
    font-size: 32px !important;
    width: 100% !important;
  }

  .landing-page {
    height: 80vh !important;
    width: 100% !important;
  }

  .landing-page-content h1 {
    font-size: 48px;
   
  }

  .develop-text {
    font-size: 48px;
  }

  .clip-container.first-line,
  .clip-container.second-line {
    height: auto;
    flex-direction: column;
   
  }
}

@media (max-width: 480px) {
  .landing-page-content {
    display: inline-block !important;
    padding-top: 10rem !important;
    font-size: 32px !important;
   
  }

  .knw{
    margin-top: 2px !important;
  }

  .landing-page {
    height: 50vh !important;
   
  }

  .landing-page-content h1 {
    font-size: 30px;
    margin-bottom: 10px;
   
  }

  .clip-container.first-line,
  .clip-container.second-line {
    width: 100%;
    height: auto;
    flex-direction: row;
   
    align-items: flex-start;
    margin-bottom: 0;
  }

  .box {
    padding: 1rem;
  }

  .navbar {
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .landing-page-content {
    padding-top: 11rem;
    margin-left: 5rem;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .landing-page-content {
    padding-top: 11rem;
    margin-left: 5rem;
  }
  html, body {
    overflow-x: hidden !important;
  }
}