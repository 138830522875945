.form-section {
    background-color: #ffffff; 
    border-radius: 50px; 
    padding: 4rem;
    width: 85%;
    margin: 0 auto;
    position: relative;
    overflow: hidden; 
  }
  
  
  .form-header {
    background-color: #99C6EA33; 
    border-radius: 50px;
    text-align: center;
    padding: 4rem;
    position: relative;
    top: -20px;
  }
  
  .form-header h1 {
    position: relative;
    font-size: 2.2rem;
    color: #5D82C6;
    margin-bottom: 1rem;
  }
  
  .get-started-btn {
    background-color: #4476B2;
    color: white;
    border: none;
    padding: 1rem 2.8rem;
    border-radius: 50px;
    font-size: 23px;
    font-weight: 600;
    cursor: pointer;
    margin-top: 2rem;
    position: absolute;
    top: 100%; 
    left: 50%;
    transform: translate(-50%, -60%);
    z-index: 1;
  }
  
  .get-started-btn:hover {
    background-color: #476fa5;
  }
  
  .get-started-btn.disabled {
    background-color: #4476B2; 
    cursor: default; 
    pointer-events: none;
  }
  
  .get-started-btn.disabled:hover {
    background-color: #4476B2; 
  }
  
  .form-body {
    background-color: #99C6EA33;
    border-radius: 50px;
    padding: 3rem;
  }
  

  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem; 
    padding: 0rem 5rem;
    padding-top: 3rem;
    font-family: 'Manrope', sans-serif;
  }
  
  .form-input {
    padding: 1rem;
    font-size: 1rem;
    border: 1px solid #C9E5F8;
    border-radius: 10px;
    background-color: #C9E5F8;
    font-family: 'Manrope', sans-serif;
  }
  
  .comp{
    background-color: #C9E5F899 !important;
    border: 1px solid #C9E5F899 !important;
  }

  .email{
    background-color: #C9E5F899 !important;
    border: 1px solid #C9E5F899 !important;
  }

  .desc{
    background-color: #C9E5F899 !important;
    border: 1px solid #C9E5F899 !important;
  }

  .half-width {
    width: 48%;
  }
  
  .name-inputs {
    display: flex;
    gap: 2rem;
  }
  
  label {
    font-size: 1rem;
    color: #000000;
  }
  
  .textarea {
    min-height: 100px;
    resize: none;
  }
  
  .submit-btn {
    background-color: #4476B2;
    color: white;
    border: none;
    padding: 1rem 3rem;
    border-radius: 50px;
    font-size: 1rem;
    cursor: pointer;
    align-self: center;
  }
  
  .submit-btn:hover {
    background-color: #476fa5;
  }
  
  .error-message {
    color: red;
    text-align: center;
    margin-top: 1rem;
  }
  

.feedback-message {
  position: fixed;
  bottom: 20px; 
  right: 20px; 
  width: 300px;
  background-color: #4caf50; 
  color: white; 
  padding: 15px; 
  border-radius: 10px; 
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2); 
  margin: 10px; 
  flex: 0 0 100px; 
  z-index: 1000; 
}

.privacy-notice {
  margin-top: 45px !important;
  margin: 1rem 2rem;
  font-size: 0.55rem;
  color: #555; 
  line-height: 1.5; 
}

  
@media (max-width: 768px) {
  .form-section {
      width: 90%;
      padding: 4rem 2rem !important; 
  }

  .form-header {
      padding: 1rem !important; 
      font-size: 24px;
      color: #5D82C6;
      margin-bottom: 0rem;
      width: 100%;
      border-radius: 40px;
  }

  .form-header h1 {
    padding: 2rem !important; 
    font-size: 20px;
    color: #5D82C6;
    margin-bottom: 0.5rem;
}

  .get-started-btn, .submit-btn {
    width: 40%;
    text-align: center;
    background-color: #4476B2;
    color: white;
    border: none;
    padding: 1rem 1rem !important;
    border-radius: 40px;
    font-size: 14px !important;
    margin-top: 1.6rem;
  }

  .name-inputs {
      flex-direction: column; 
      gap: 1rem; 
  }

  .half-width {
      width: 100%; 
  }

  .form-input {
      font-size: 0.9rem; 
  }

  label {
      font-size: 0.9rem; 
  }
  .form-body{
    padding: 0rem !important;
  }
}

@media (max-width: 480px) {
  .form-body{
    padding: 1rem !important;
  }

  .contact-form{
    padding: 2rem 0.8rem !important;
  }
  .form-section {
    
      padding: 1rem 0rem !important;
      margin-top: 2rem !important;
  }

  .form-input{
    padding: 0.5rem;
    
  }

  .form-header {
      padding: 2rem 0rem !important; 
  }

  .feedback-message {
      width: 250px; 
  }

  .privacy-notice {
      font-size: 0.45rem; 
  } 
}

@media (min-width: 769px) and (max-width: 1024px) {
  .form-section {
    width: 90%;
    padding: 4rem 2rem !important; 
}

.form-header {
    padding: 2rem !important; 
    font-size: 28px !important;
    color: #5D82C6;
    margin-bottom: 0rem;
    width: 100%;
    border-radius: 40px;
}

.form-header h1 {
  padding: 2rem !important; 
  font-size: 20px;
  color: #5D82C6;
  margin-bottom: 0.5rem;
}

.get-started-btn, .submit-btn {
  width: 20%;
  text-align: center;
  background-color: #4476B2;
  color: white;
  border: none;
  padding: 1rem 1rem !important;
  border-radius: 40px;
  font-size: 16px !important;
  margin-top: 1.6rem;
}

.name-inputs {
    flex-direction: column; 
    gap: 1rem; 
}

.half-width {
    width: 100%; 
}

.form-input {
    font-size: 0.9rem; 
}

label {
    font-size: 0.9rem; 
}
.form-body{
  padding: 0.5rem !important;
}
}
  