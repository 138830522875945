.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 0.2rem 6rem 0rem 6rem;
    background-color: #ffffff; 
  }
  
  .footer-left {
    display: flex;
    flex-direction: column;
  }
  
  .brand-logo {
    width: 110px;
    height: auto;
    margin-bottom: 1px;
    margin-left: 10px;
  }
  
  .small-pic {
    width: 120px; 
    height: auto;
  }
  
  .footer-right {
    display: flex;
    justify-content: space-between;
    gap:5px; 
    flex: 0.6; 
  }
  
  .footer-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -4.5rem;
  }

  .footer-buttons-container {
    display: flex;
    flex-direction: column; 
    margin-top: 1rem;
    
}

.footer-btn, .back-button {
  cursor: pointer;
  align-items: center;
  text-align: center;
  justify-content: center;
  display: inline-block;
  padding: 0.5rem 1.2rem;
  color: #4476B2;
  background-color: white;
  border: 2px solid #4476B2;
  text-decoration: none;
  transition: color 0.5s ease;
  position: relative;
  overflow: hidden;
  z-index: 1;
  margin-bottom: 20px;
  border-radius: 12px;
  width: max-content;
}

.footer-btn:hover, .back-button:hover {
  color: white;
}

.footer-btn::before, .back-button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #4476B2;
  z-index: -1; 
  transform: scaleX(0); 
  transform-origin: left; 
  transition: transform 0.4s ease; 
}

.footer-btn:hover::before, .back-button:hover::before {
  transform: scaleX(1);
}

  
  .footer-section .icon {
    width: 24px;
    height: auto;
    margin-right: 0.5rem;
  }
  
  .footer-section p {
    margin: 0;
    font-size: 14px;
    color: #000000;
  }
 
  .rights {
    align-items: center;
    color: #b7b5b5;
    text-align: center; 
    padding-top: 1rem;
    margin-top: 8rem;
    font-size: 10px;
    width: 80%; 
    position: absolute !important; 
    padding-left: 4rem;
  }
  
  
  @media (max-width: 768px) {
    .footer {
      width: 100% !important;
      padding: 1rem; 
      flex-direction: row; 
      justify-content: space-between; 
      align-items: flex-start; 
      margin: 0 !important; 
      overflow-y: auto !important; 
    }
  
    .footer-left {
      display: flex; 
      justify-content: flex-start;
      align-items: flex-start; 
      margin-bottom: 0; 
      width: 50%; 
    }
  
    .footer-section p {
      font-size: 10px;
    }
  
    .footer-section {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0; 
    }
    
    .footer-section .icon {
      width: 24px; 
      height: auto;
      margin-right: 0.5rem; 
    }
    
    .footer-right {
      display: flex;
      justify-content: flex-end; 
      flex-direction: column; 
      align-items: flex-start; 
      margin-bottom: 0; 
      width: 50%; 
      flex: 0;
    }
    
    .footer-buttons-container {
      display: flex;
      flex-direction: row;  
      margin-top: 1.5rem;
      margin-left: -9rem;
      gap: 1rem;
      
  }

  .footer-btn{
    padding: 0.3rem 0.8rem;
    font-size: 10px;
    cursor: pointer;
  }
    
  
    .rights {
      padding-left: 0; 
      width: 100%;
      position:fixed !important;
      bottom: -5px !important;
    }
  }

@media (min-width: 769px) and (max-width: 1024px) {
  .footer {
    width: 100% !important;
    padding: 0 3rem; 
    flex-direction: row; 
    justify-content: space-between; 
    align-items: flex-start; 
    margin: 0 !important; 
    overflow-y: auto !important; 
  }

  .footer-left {
    display: flex; 
    justify-content: flex-start;
    align-items: flex-start; 
    margin-bottom: 0; 
    width: 50%; 
  }

  .footer-section p {
    font-size: 12px;
  }

  .footer-section {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0; 
  }
  
  .footer-section .icon {
    width: 24px; 
    height: auto;
    margin-right: 0.5rem; 
  }
  
  .footer-right {
    display: flex;
    justify-content: flex-end; 
    flex-direction: column; 
    align-items: flex-start; 
    margin-bottom: 0; 
    width: 50%; 
    flex: 0;
  }
  
  .footer-buttons-container {
    display: flex;
    flex-direction: row;  
    margin-top: 1.5rem;
    align-items: flex-start !important;
    gap: 1rem;
    
}

.footer-btn{
  padding: 0.3rem 0.8rem;
  font-size: 10px;
  cursor: pointer;
}
  
  .rights {
    padding-left: 0; 
    width: 100%;
    position:absolute !important;
    bottom: -15px !important;
  }

}
  