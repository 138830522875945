.proj{
  padding: 4rem 0rem;
}
.titleP {
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 3rem;
  margin-top: 0.5rem;
  color: #4476B2;
  text-align: center;
}
.projects-container {
  display: grid;
  grid-gap: 5rem;
  padding: 5rem;
  background-color: #7094BE;
}

.project-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 10rem;
  margin-right: 10rem;
}

.project-logo {
  width: 400px; 
  height: 400px;
  border-radius: 50%; 
  background-color: white;
  display: flex; 
  align-items: center;
  justify-content: center;
}


.project-logo img {
  width: 60%; 
  height: auto; 
}

.project-logo .imgicn{
  width: 40% !important;
  height: auto;
}

.project-item.left .project-logo {
  order: 1;  
}

.project-item.right .project-logo {
  order: 2;
}

.project-item.right{
  flex-direction: row-reverse;
}

.project-info {
  width: 500px;
}

.project-info h1 {
  font-size: 38px;
  color: white;
  margin-bottom: 0.5rem;
}

.project-info p {
  font-size: 16px;
  color: white;
  margin-bottom: 1rem;
}

.underline {
  width: 51px;
  height: 7px;
  background-color: #D9D9D9;
  margin-top: 0.5rem;
  border-radius: 11px;
  width: 0;
  opacity: 0; 
}


@media (max-width: 768px) {
  .projects-container {
    grid-gap: 3rem;
    padding: 3rem;
  }

  .project-item {
    margin-left: 2rem;
    margin-right: 2rem;
    text-align: left !important;
  }

  .project-logo {
    width: 250px;
    height: 250px;
  }

  .project-logo img {
    width: 70%;
  }

  .project-info {
    width: 250px;
  }

  .project-info h1 {
    font-size: 24px;
  }

  .project-info p {
    font-size: 14px;
    text-align: left !important;
  }
  .project-item.left {
    flex-direction: row;
  }
}

@media (max-width: 480px) {
  .project-item.left {
    flex-direction: row;
  }

  .proj{
    padding: 0rem !important;
  }

  .titleP{
    font-size: 20px;
    padding-top: 1rem !important;
  }
  .projects-container {
    grid-gap: 2rem;
    padding: 1rem;
  }

  .project-item {
    flex-direction: column;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .project-logo {
    width: 95px;
    height: 95px;
  }

  .project-logo img {
    width: 80%;
  }

  .project-info {
    width: 65% !important;
    text-align: left;
    margin-top: 1rem;
  }

  .project-info h1 {
    font-size: 20px;
  }

  .project-info p {
    font-size: 10px;
    text-align: left !important;
  }

  .underline {
    text-align: left;
    height: 5px;
  
  }
}

@media (min-width: 1024px) and (max-width: 1280px) {
  .projects-container {
    grid-gap: 3rem;
    padding: 3rem;
  }

  .project-item {
    margin-left: 4rem;
    margin-right: 4rem;
    text-align: left !important;
  }

  .project-logo {
    width: 320px;
    height: 320px;
  }

  .project-logo img {
    width: 70%;
  }

  .project-info {
    width: 50%;
  }

  .project-info h1 {
    font-size: 32px;
  }

  .project-info p {
    font-size: 16px;
    text-align: left !important;
  }
  .project-item.left {
    flex-direction: row;
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .projects-container {
    grid-gap: 3rem;
    padding: 4rem 0rem;
  }

  .project-item {
    margin-left: 4rem;
    margin-right: 4rem;
    text-align: left !important;
  }

  .project-logo {
    width: 320px;
    height: 320px;
  }

  .project-logo img {
    width: 70%;
  }

  .project-info {
    width: 50%;
  }

  .project-info h1 {
    font-size: 32px;
  }

  .project-info p {
    font-size: 14px;
    text-align: left !important;
  }
  .project-item.left {
    flex-direction: row;
  }
}